webix.protoUI({
	name:"kanbanuserlist",
	defaults:{
		width:300,
		layout:"y",
		scroll:true,
		yCount:4,
		autoheight:false,
		select:true,
		template:function(obj){
			if (obj.image)
				return "<img class='webix_kanban_list_avatar' src='"+obj.image+"'>"+obj.value;

			return "<span class='webix_icon webix_kanban_icon kbi-account webix_kanban_list_avatar'></span>"+obj.value;
		}
	},
	$init(){
		this.$ready.push(function(){
			this.attachEvent("onShow", () => {
				let user_id = this.getContext().user_id;

				if ( user_id && this.exists(user_id) ){
					this.select(user_id);
					this.showItem(user_id);
				} else this.unselectAll();
			});

			this.attachEvent("onMenuItemClick", (id) => {
				let kanban = this.getKanban();
				let selectedTask = this.getContext().id;

				kanban.updateItem(selectedTask, {user_id:id});
			});

			this.type.master = this.config.masterId;
		});
	},
	getKanban(){
		return webix.$$(this.config.masterId);
	}
}, webix.ui.contextmenu);
