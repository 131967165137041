import {type} from "./common";

var dtype = webix.copy(type);
dtype.width = 200;

webix.protoUI({
	name:"kanbandataview",
	$init(){
		this.$view.className += " webix_kanban_list";
		this.$ready.push(webix.bind(this._setHandlers,this));
	},
	defaults:{
		prerender:true,
	},
	type:dtype
}, webix.ui.dataview, webix.KanbanView);