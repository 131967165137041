import "./uploader";

import "./locale";

webix.protoUI({
	name:"kanbaneditor",
	defaults:{
		width:534,
		position:"center",
		css:"webix_kanban_editor",
		modal:true,
		move:true
	},
	$init(config){
		let kanban = webix.$$(config.master);

		config.head = {
			view:"toolbar", paddingX:17, paddingY:8,
			elements:[
				{ view:"label", label:webix.i18n.kanban.editor.add, localId:"$kanban_header"},
				{ view:"icon", icon:"wxi-close", click:() => this._close()}
			]
		};

		let c = kanban.config.editor;
		let form = { view:"form", borderless:true, padding:0, elementsConfig:{ labelPosition:"top" } };
		let elements = [
			{ view:"textarea", label:webix.i18n.kanban.editor.text, name:"text", height:90},
			{ view:"multicombo", label:webix.i18n.kanban.editor.tags, name:"tags", options:kanban._tags, $hide:true},
			{ margin:8,
				cols:[
					{ view:"combo", label:webix.i18n.kanban.editor.assign, name:"user_id", $hide:true, options:{
						body:{ data:kanban._users, yCount:5 }
					}},
					{ view:"richselect", label:webix.i18n.kanban.editor.color,  name:"color", $hide:true, options:{
						body:{
							yCount:5, data:kanban._colors, css:"webix_kanban_colorpicker",
							template:"<span class='webix_kanban_color_item' style='background-color: #color#'></span>#value#"
						}
					}},
					{ view:"richselect", label:webix.i18n.kanban.editor.status, name:"$list", options:{
						body:{ data:kanban._statuses, yCount:5 }
					}}
				]
			}
		];
		if (webix.isArray(c))
			form.elements = c;
		else if (typeof c === "object"){
			form = webix.extend(form, c, true);
			form.view = "form";
			form.elements = form.elements || form.rows || (form.cols ? [{cols:form.cols}] : elements);
			delete form.rows; delete form.cols;
		} else form.elements = elements;

		if (kanban.config.attachments)
			form.elements.push({ margin:0, rows:[
				{
					cols:[
						{ view:"label", label:webix.i18n.kanban.editor.attachments },
						{ view:"kanbanuploader", label:webix.i18n.kanban.editor.upload, upload:kanban.config.attachments,
							name:"attachments", autowidth:true, css:"webix_transparent webix_kanban_uploader", type:"icon"
						}
					]
				},
				{ view:"dataview", localId:"$kanban_dataview_uploader", yCount:1, borderless:true,
					type:"uploader", css:"webix_kanban_dataview_uploader",
					on:{
						onItemDblClick:(id, e, node) => {
							let link = node.getElementsByTagName("a")[0];
							link.click();
						}
					}
				}
			]});

		config.body = { paddingX:17, paddingY:0, margin:16,
			rows:[
				form,
				{
					cols:[
						{ view:"button", label:webix.i18n.kanban.remove, type:"danger", autowidth:true,
							hidden:true, localId:"$kanban_remove",
							click:() => {
								let values = this.getValues({hidden:false});
								let kanban = this.getKanban();
								if (!kanban.callEvent("onBeforeEditorAction", ["remove", this, values])) return;

								kanban._removeCard(values.id).then(() => this._close());
							}
						},
						{ },
						{ view:"button", label:webix.i18n.kanban.save, type:"form", autowidth:true,
							click:() => {
								let values = this.getValues({hidden:false});
								let kanban = this.getKanban();
								if (!kanban.callEvent("onBeforeEditorAction", ["save", this, values])) return;

								this._fixStatus(values, kanban);
								if ( kanban.exists(values.id) ){
									values.$list = kanban.getItem(values.id).$list;
									kanban.updateItem(values.id, values);
								}
								else kanban.add(values);
								this._close();
							}
						}
					]
				},
				{ height:1 }
			]

		};

		this.$ready.push(this._afterInit);
	},
	_afterInit:function(){
		this._form = this.queryView({view:"form"});
		this._removeBtn = this.queryView({localId:"$kanban_remove"});
		this._header = this.queryView({localId:"$kanban_header"});

		let uploader = this.queryView({view:"kanbanuploader"});
		if (uploader){
			let dataview = this.queryView({localId:"$kanban_dataview_uploader"});
			uploader.define( "link", dataview.config.id );
			uploader.addDropZone(dataview.$view);
			webix.extend(dataview, webix.OverlayBox);
		}

		let views = this.queryView({$hide:true}, "all");
		if (views.length)
			this.attachEvent("onShow", () => {
				for (let i=0; i<views.length; i++)
					if (views[i].getList().count())
						views[i].show();
					else views[i].hide();
			});
	},
	_fixStatus(values, kanban){
		values.$list = Number(values.$list) || 0;
		if (kanban._sublists[values.$list])
			kanban.setListStatus(values, kanban._sublists[values.$list]);
	},
	getForm(){
		return this._form;
	},
	getKanban(){
		return webix.$$(this.config.master);
	},
	setValues(values){
		if (typeof values !== "object" || !values)
			values = {};
		let kanban = this.getKanban();
		let listIndex = kanban._assignList(values);
		values.$list = values.$list || (listIndex !== -1 ? listIndex : 0);

		this._prepareEditor(kanban, values.id);
		this._form.setValues(values);
	},
	getValues(details){
		return this._form.getValues(details);
	},
	_prepareEditor(kanban, id){
		if (id && kanban.exists(id)){
			this._removeBtn.show();
			this._header.define("label", webix.i18n.kanban.editor.edit);
		} else {
			this._removeBtn.hide();
			this._header.define("label", webix.i18n.kanban.editor.add);
		}
		this._header.refresh();
	},
	_close(){
		this.hide();
		this._form.clear();
		this._prepareEditor();
	}
}, webix.ui.window);