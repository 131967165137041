webix.protoUI({
	name:"kanbanheader",
	$kanban:true,
	$skin:function(){
		this.defaults.height = webix.skin.$active.barHeight;
		this._template_types.sub.height = webix.skin.$active.barHeight - 12;
	},
	$init:function(config){
		let subtype = this._template_types[config.type];
		if (subtype)
			webix.extend(config, subtype);
	},
	defaults:{
		css:"webix_kanban_header",
		borderless:true,
		template:function(){
			let icon = this.icon||(this.link?"webix_kanban_icon kbi-plus-circle":"");
			return (icon ? "<span class='webix_icon "+(this.link?"webix_kanban_add_icon ":"")+icon+"'></span>" : "") +
				"<span class='webix_strong' style='line-height:"+this.height+"px'>"+(this.label||"")+"</span>";
		},
	},
	_template_types:{
		"sub":{
			css:"webix_kanban_sub_header"
		}
	},
	on_click:{
		"webix_kanban_add_icon":function(){
			let obj = { text:"" };
			let kanban = this.getKanban();
			let list = kanban.queryView({id:this.config.link});

			if (list && this.callEvent("onBeforeCardAdd",[obj, list])){
				kanban.setListStatus(obj, list);
				kanban.add(obj);
			}
		}
	},
	getKanban(){
		return webix.$$(this.config.master);
	},
}, webix.MouseEvents, webix.ui.template);