const images = { jpg:true, jpeg:true, png:true, gif:true };

const icons = { ppt:"-powerpoint", pptx:"-powerpoint", pptm:"-powerpoint", pps:"-powerpoint", ppsx:"-powerpoint", ppsm:"-powerpoint",
	doc:"-word", docx:"-word", docm:"-word", xls:"-excel", xlsx:"-excel", xlsm:"-excel", xlsb:"-excel", pdf:"-pdf",
	wav:"-audio", aif:"-audio", mp3:"-audio", mid:"-audio", mpg:"-video", mov:"-video", wmv:"-video", avi:"-video", mp4:"-video",
	zip:"-archive", jar:"-archive", rar:"-archive", gz:"-archive", jpg:"-image", jpeg:"-image", png:"-image", gif:"-image"
};

export function isImage(key){
	return images[key.toString().toLowerCase()];
}

export function getIconName(key){
	return icons[key.toString().toLowerCase()] || "";
}