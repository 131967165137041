webix.protoUI({
	name:"kanbanchat",
	$init(config){
		config.padding = 0;

		this.$ready.push(function(){
			this.attachEvent("onHide", this._hide_chat);

			let list = this.queryView({view:"list"});
			list.data.attachEvent("onStoreUpdated", (id, obj, mode)=>{
				if (mode && mode !== "paint")
					this._save();
			});
		});
	},
	_save(){
		let context = this.getContext();
		let comments = this.getBody();
		let kanban = this.getKanban();

		if (context && kanban.exists(context.id))
			kanban.updateItem(context.id, {comments:comments.serialize()});
	},
	_hide_chat(){
		let comments = this.getBody();
		comments.queryView({view:"form"}).clear();
		comments.queryView({view:"list"}).clearAll();
	},
	getKanban(){
		return webix.$$(this.config.masterId);
	}
}, webix.ui.context);
