import {isImage, getIconName} from "./helpers/image";

webix.protoUI({
	name:"kanbanuploader",
	$init(){
		this.files.data.scheme({
			$init:(obj) =>{
				if (typeof obj.link === "string" && obj.link){
					obj.name = obj.name || obj.link.split("/").pop();
					obj.type = obj.type || obj.name.split(".").pop();
					obj.status = obj.status || "server";
				}
				obj.sizetext = obj.sizetext || this._format_size(obj.size);
			}
		});

		this.files.data.attachEvent("onStoreUpdated", () => {
			let view = webix.$$(this.config.link);
			let html = "<span class='webix_strong'>" + (webix.i18n.kanban.dnd||"") + "</span>";
			if (!this.files.data.count())
				view.showOverlay(html);
			else view.hideOverlay();
		});
	},
	defaults:{
		icon:"webix_kanban_icon kbi-upload",
	},
	getValue(){
		let data = [];
		this.files.data.each(function(obj){
			if (obj.status === "server")
				data.push( {id:obj.id, link:obj.link, size:obj.size} );
		});
		return data;
	},
	_format_size(size){
		let index = 0;
		while (size > 1024){
			index++;
			size = size/1024;
		}
		return Math.round(size*100)/100 + " " + webix.i18n.fileSize[index];
	}
}, webix.ui.uploader);

webix.type(webix.ui.dataview, {
	name:"uploader",
	height:91,
	width:161,
	template:(obj, common) => {
		return `<a${(obj.status === "server") ? ` href="${obj.link}" download="${obj.name}"` : ""}></a>
				${common.body(obj)}
				${common.title(obj,common)}
				${common.removeIcon(obj)}`;
	},
	body:function(obj){
		if (obj.status === "server" && isImage(obj.type))
			return `<div class="webix_kanban_uploader_body"><img src="${obj.link}"></div>`;

		return `<div class='webix_kanban_uploader_body'>
					<span class='webix_icon webix_kanban_icon kbi-file${getIconName(obj.type)}'></span>
				</div>`;
	},
	title:function(obj,common){
		return `<div class="webix_kanban_uploader_title" title="${obj.name}">
					${common.progress(obj)}
					<div class="webix_kanban_uploader_label">${obj.name}</div>
				</div>`;
	},
	progress:function(obj){
		switch(obj.status){
			case "client": return "<span class='webix_kanban_uploader_progress'>"+obj.sizetext+"</span>";
			case "transfer": return "<span class='webix_kanban_uploader_progress'>"+obj.percent+"%</span>";
			case "server": return "<span class='webix_kanban_uploader_progress_server'>"+obj.sizetext+"</span>";
			default: return "<span class='webix_kanban_uploader_progress_error'>ERROR</span>";
		}
	},
	removeIcon:function(){
		return "<div class='webix_kanban_remove_upload'><span class='webix_icon wxi-close'></span></div>";
	},
	on_click:{
		"webix_kanban_remove_upload":function(ev, id){
			webix.$$(this.config.uploader).files.remove(id);
			return webix.html.preventEvent(ev);
		}
	}
});