webix.protoUI({
	name:"kanbanmenu",
	$init(){
		this.$ready.push(function(){
			this.attachEvent("onItemClick", function(id){
				let cid = this.getContext().id;
				let kanban = this.getKanban();

				if ( kanban.callEvent("onBeforeCardAction", [id, cid]) ){
					switch(id){
						case "edit":
							kanban.showEditor(webix.copy( kanban.getItem(cid) ));
							break;
						case "copy":
							kanban.copy(cid);
							break;
						case "remove":
							kanban._removeCard(cid);
							break;
					}
				}
			});
		});
	},
	getKanban(){
		return webix.$$(this.config.masterId);
	}
}, webix.ui.contextmenu);