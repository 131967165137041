import {type} from "./common";

webix.protoUI({
	name:"kanbanlist",
	$init(){
		this.$view.className += " webix_kanban_list";
		this.$ready.push(webix.bind(this._setHandlers,this));
	},
	defaults:{
		scroll:"auto",
	},
	type: type
}, webix.ui.list, webix.KanbanView);